import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import createDefaultTheme from 'theme';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const cache = createCache({
  key: 'css',
  prepend: true
});

const defaultTheme = createDefaultTheme(rootElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ThemeProvider theme={defaultTheme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
