import { PaletteOptions } from '@mui/material';

export const baseColors = {
  black: '#161616',
  blue: '#0000FF',
  gray: '#6e6e6e',
  gray2: '#A1A1A1',
  gray3: '#E0E0E0',
  gray4: '#E4E2E2',
  gray5: '#E7E4E1',
  'light-gray': '#f8f8f8',
  green: '#CAEFCE',
  green2: '#36663B',
  green3: '#82C684',
  orange: '#ff421c',
  pink: '#ffccc1',
  pink2: '#FFECE8',
  purple: '#EAE7F1',
  purple2: '#D8D8FF',
  red: '#FFD6D6',
  red2: '#B84838',
  yellow: '#FCF8D4',
  yellow2: '#A79A2B',
  white: '#fff'
};

const defaultPalette: PaletteOptions = {
  mode: 'light',
  primary: { main: baseColors.orange },
  warning: { main: baseColors.pink }
};

export default defaultPalette;
