import { createTheme } from '@mui/material';
import ComponentsOverrides from './overrides';
import defaultPalette from './palette';
import defaultTypography from './typography';

const createDefaultTheme = (rootElement: HTMLElement) => {
  const defaultTheme = createTheme({
    palette: defaultPalette,
    typography: defaultTypography
  });

  const theme = defaultTheme;
  theme.components = { ...defaultTheme.components, ...ComponentsOverrides(theme, rootElement) };

  return theme;
};

export default createDefaultTheme;
