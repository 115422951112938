import { AccountConfirmationPage, AccountConfirmedPage, Page404 } from 'pages';
import { createBrowserRouter } from 'react-router-dom';
import ROUTE_PATHS from './paths';
import SlackInstallPage from 'pages/SlackInstallPage';
import SlackAuthorizedPage from 'pages/SlackAuthorizedPage';

const router = createBrowserRouter([
  {
    path: ROUTE_PATHS.ACCOUNT_CONFIRMATION,
    element: <AccountConfirmationPage />,
    errorElement: <Page404 />
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    element: <Page404 />
  },
  {
    path: ROUTE_PATHS.SLACK_INSTALL,
    element: <SlackInstallPage />
  },
  {
    path: ROUTE_PATHS.SLACK_AUTHORIZED,
    element: <SlackAuthorizedPage />
  },
  {
    path: ROUTE_PATHS.ACCOUNT_CONFIRMED,
    element: <AccountConfirmedPage />,
    errorElement: <Page404 />
  }
]);

export default router;
