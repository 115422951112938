import { Avatar, Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { supabaseClient } from 'api/supabaseClient';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';
import { Copyright } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const AccountConfirmationPage = () => {
  const search = useLocation().hash;

  const [tokenDetected, setTokenDetected] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<{ password: string }>();

  const handleFormSubmit = async ({ password }: { password: string }) => {
    const { data, error } = await supabaseClient.auth.updateUser({ password: password });

    if (data && !error) {
      reset();
      supabaseClient.auth.signOut();
      window.location.href = 'https://platform.getprosperapp.com/';
    }
  };

  useEffect(() => {
    if (tokenDetected) return;

    const urlParams = new URLSearchParams(search);
    if (urlParams.has('#access_token')) {
      setTokenDetected(true);
    } else {
      navigate(ROUTE_PATHS.NOT_FOUND);
    }
  }, [navigate, search, tokenDetected]);

  if (!tokenDetected) {
    return <div></div>;
  }

  return (
    <Container component="main" maxWidth="lg">
      <Grid container>
        <Grid item xs={12} md={4} lg={4}></Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container>
            <Grid xs={12} className="flex justify-center mt-2">
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }} src={'../../assets/img/icon128.png'} />
            </Grid>
            <Grid xs={12} className="flex justify-center">
              <Typography component="h1" variant="h5">
                Welcome to Prosper
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Typography variant="body1" className="mt-4 mb-4">
              Please enter your new password below.
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true, minLength: 6 }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      label="Password"
                      type="password"
                      fullWidth
                      name={name}
                      value={value || ''}
                      placeholder={'Enter your password here...'}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
                {errors.password && <span className="text-red2">Password is required</span>}
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type="submit" variant="contained" disabled={!isValid}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>

          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none',
              marginTop: '3rem'
            }}
            id="copyright-box"
            data-testid="copyright-box"
          >
            <Copyright />
            <Typography
              variant="body1"
              fontSize="small"
              component="a"
              href="https://useprosper.co"
              target="_blank"
              rel="noreferrer noopener"
              gutterBottom
            >
              Prosper | All rights reserved
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountConfirmationPage;
