import { Grid, Typography } from '@mui/material';

const AccountConfirmedPage = () => {
  return (
    <Grid container className="gap-4 p-4">
      <Grid item xs={0} md={4} lg={4}></Grid>
      <Grid item xs={12} md={4} lg={4} className="text-center">
        <Typography variant="h1" className="mt-8 mb-8">
          Thanks for confirming your account!
        </Typography>
        <Typography variant="body1">
          You can now sign-in to Prosper Chrome extension to start enjoying its capabilites.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountConfirmedPage;
