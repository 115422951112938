import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const SlackAuthorizedPage = () => {
  const [installComplete, setInstallComplete] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const processing = useRef<boolean>(false);

  const executePostAuthorize = async (state: string, code: string) => {
    try {
      processing.current = true;
      const response = await fetch(`${process.env.REACT_APP_SLACK_HANDLER_URL}/post-authorize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code, state })
      });

      if (response.ok) {
        setInstallComplete(true);
      } else {
        setError('Invalid authorization link, please try again or contact a Prosper admin.');
      }
    } catch (error) {
      setError(
        'Error occurred while installing the bot, please try again or contact a Prosper admin.'
      );
    } finally {
      processing.current = false;
    }
  };

  useEffect(() => {
    const processAuthorization = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        const code = urlParams.get('code');

        if (!state || !code) {
          setError('Invalid authorization link, please try again or contact a Prosper admin.');
          return;
        }

        executePostAuthorize(state, code);
      } catch (error) {
        console.error('Error fetching authorize URL:', error);
      }
    };

    if (processing.current) return;

    processAuthorization();
  }, [processing]);

  if (error) {
    return (
      <Grid container className="gap-4 p-4">
        <Grid item xs={0} md={4} lg={4}></Grid>
        <Grid item xs={12} md={4} lg={4} className="text-center">
          <Typography variant="h1" className="mt-8 mb-8 text-7xl">
            Something went wrong!
          </Typography>
          <Typography variant="body1" className="mb-8 text-3xl">
            {error}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className="gap-4 p-4">
      <Grid item xs={0} md={4} lg={4}></Grid>
      <Grid item xs={12} md={4} lg={4} className="text-center">
        {installComplete && !processing.current ? (
          <Box className="scale-100">
            <Typography variant="h1" className="mt-8 mb-8 text-7xl">
              Thanks for installing Prosper's bot!
            </Typography>
            <Typography variant="body1" className="mb-8 text-3xl">
              We have completed the authorization process and you can now start using the bot in
              your Slack workspace.
            </Typography>
          </Box>
        ) : (
          <Box className="block">
            <CircularProgress />
            <Typography variant="body1" className="mb-8 text-3xl">
              Completing installation process...
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SlackAuthorizedPage;
