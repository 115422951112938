import { Theme } from '@mui/material/styles';
import Table from './Table';
import Dialog from './Dialog';

export default function ComponentsOverrides(theme: Theme, rootElement: HTMLElement): object {
  return {
    ...Table(theme),
    // Dialog, Modals, PopOver and Tooltip require rootElement reference in order for MUI to detect tailwind css classes, more at:
    // https://github.com/mui/material-ui/issues/36390
    // https://github.com/mui/material-ui/issues/33424
    ...Dialog(theme, rootElement)
  };
}
