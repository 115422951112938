import { createClient } from '@supabase/supabase-js';

const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_KBB_URL ?? '',
  process.env.REACT_APP_SUPABASE_KBB_ANON_KEY ?? ''
);

// TODO: remove empty tables
const KBB_SCHEMA = {
  CATEGORY_TABLE: 'categories',
  EVALUATIONS_TABLE: 'evaluations',
  EXTRACTED_QUESTIONS_VIEW: 'faqs_vw',
  ARTICLE_DRAFTS_TABLE: 'article_drafts',
  GENERATED_DRAFTS_TABLE: '',
  QUESTIONS_CONVERSATIONS_VIEW: '',
  FAQS_ARTICLES_VIEW: 'faqs_existing_articles_vw',
  FAQS_EXISTING_ARTICLES_TABLE: 'faqs_existing_articles',
  EXISTING_ARTICLES_TABLE: 'existing_articles',
  ARTICLE_DRAFT_COMMENT: 'article_draft_comments',
  PROFILES_TABLE: 'profiles',
  ORGANIZATIONS_TABLE: 'organizations',
  SOURCES_TABLE: 'sources',
  FAQ_TABLE: 'faqs',
  FAQ_STATUS_TABLE: 'faq_statuses',
  FAQ_SOURCES_VIEW: 'faq_sources_vw'
};

export { KBB_SCHEMA, supabaseClient };
