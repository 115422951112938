import { TypographyOptions } from '@mui/material/styles/createTypography';
import { baseColors } from './palette';

export const FONT_PRIMARY = 'Manrope';
export const FONT_SECONDARY = 'Inter';

const defaultTypography: TypographyOptions = {
  fontFamily: `${FONT_PRIMARY}, ${FONT_SECONDARY}`, // CDN FONTS
  body1: {
    color: baseColors.black,
    fontSize: '.875rem',
    fontWeight: 500
  },
  body2: {
    color: baseColors.black,
    fontSize: '.75rem',
    fontWeight: 600
  },
  h1: {
    fontFamily: FONT_PRIMARY,
    fontSize: '2rem',
    fontWeight: 400,
    lineHeight: '3.125rem'
  }
};

export default defaultTypography;
