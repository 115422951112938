import { Theme } from '@mui/material/styles';
import { baseColors } from 'theme/palette';

export default function Table(theme: Theme): object {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child': {
            borderBottom: `1px solid ${baseColors.gray4}`
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: `1px solid ${baseColors.gray3}`,
          fontWeight: 600,
          fontSize: '.75rem',
          textTransform: 'uppercase',
          height: '3.25rem',
          '&:first-of-type': {
            paddingLeft: '1.5rem'
          },
          '&:last-of-type': {
            paddingRight: '1.5rem'
          }
        },
        body: {
          color: baseColors.black,
          fontSize: '.875rem',
          fontWeight: 500,
          height: '4rem',
          '&:first-of-type': {
            paddingLeft: '1.5rem'
          },
          '&:last-of-type': {
            paddingRight: '1.5rem'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
